import React from "react";
import { FDate } from "~/components/FDate";

type Props = {
  start: Date;
  end: Date;
};
export const DateRange = ({ start, end }: Props) => {
  return (
    <>
      From <FDate date={start} /> to <FDate date={end} />
    </>
  );
};
